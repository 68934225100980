import { useSettings } from '@wix/tpa-settings/react';

import settingsParams from '../settingsParams';

import { ISection, SectionTypes } from '../types';

export const useWidgetSections = (): ISection[] => {
  const settings = useSettings();
  const sections: ISection[] = settings.get(settingsParams.sections);
  if (sections.map(({ type }) => type).indexOf(SectionTypes.GALLERY) === -1) {
    sections.push({
      type: SectionTypes.GALLERY,
      visible: true,
      bookButton: false,
    });
  }

  return sections;
};
